<template>
	<div class="row">
		<div class="col-12 mb-2">
			<div class="card mb-1">
				<div class="card-body">
					<div class="row">
						<div class="col">
							<label class="font-15 mb-2"><i class="far fa-search color-theme font-13 mr-1"></i> {{ $t('server.search.label') }}</label>
							<input type="text" class="form-control rounded" :placeholder="$t('server.search.placeholder')" v-model="pesquisa.valor" v-focus @keyup.enter="searchServers">
						</div>
						<div class="w-max-content align-self-end">
							<button class="btn btn-primary w-100 px-sm-5" @click="searchServers"><i class="far fa-search font-13"></i></button>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="col-12" v-if="pesquisa.resultado.length > 0">
			<div class="card">
				<div class="card-body">
					<div class="cards-2">
						<server v-for="(server, index) in pesquisa.resultado" :key="index" :server="server" :index="index" @selectServer="selectServer($event)" />
					</div>
				</div>
			</div>
		</div>

		<!-- modalServer -->
      <div class="modal fade" id="modalServer" tabindex="-1" aria-labelledby="modalServerLabel" aria-hidden="true">
         <div class="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title h4" id="modalServerLabel">Server ID "{{ server.id_s }}"</h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">×</span></button>
               </div>
               <div class="modal-body">
						<div class="card">
							<div class="card-body">
								<div class="row">
									<div class="col-12 mb-2 d-flex align-self-end">
										<label class="mr-2"><i class="far fa-sparkles font-13 color-theme mr-1"></i> Premium</label>
										<label class="switch">
                                 <input type="checkbox" v-model="server.premium"><span class="slider"></span>
                              </label>
									</div>
									<div class="col-12 mb-3" v-if="server.premium">
										<label class="mb-1"><i class="far fa-star font-13 color-theme mr-1"></i> Premium pack</label>
										<select class="form-control" v-model="server.premiumPack" :disabled="!server.premium">
											<option :value="$t('premium.config.basic')">{{ $t("premium.config.basic") }}</option>
											<option :value="$t('premium.config.standard')">{{ $t("premium.config.standard") }}</option>
											<option :value="$t('premium.config.ultimate')">{{ $t("premium.config.ultimate") }}</option>
										</select>
									</div>
									<div class="col-12">
										<label class="mb-1"><i class="far fa-calendar font-13 color-theme mr-1"></i> {{ $t("server.modal.label.premiumDays") }}</label>
										<input type="text" class="form-control" v-model="server.premiumDays" v-mask="'##########'" v-if="server.premium" />
										<input type="text" class="form-control" :value="$t('server.modal.placeholder.premiumDays')" v-else disabled />
									</div>
								</div>
							</div>
						</div>
               </div>
               <div class="modal-footer">
                  <button type="button" class="btn btn-round btn-primary" @click="savePremium">{{ $t("server.modal.buttons.save") }}</button>
                  <button type="button" class="btn btn-round btn-secondary" data-dismiss="modal">{{ $t("server.modal.buttons.close") }}</button>
               </div>
            </div>
         </div>
      </div>
	</div>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'
import server from '@/components/Server.vue'

export default {
	name: 'Servers',
	data: function () {
		return {
			pesquisa: {'valor': '', 'resultado': []},
			server: {'id_s': null, 'premiumPack': null, 'premium': null, 'premiumDays': null}
		}
	},
	computed: {
		...mapState({
			tokenSessao: state => state.tokenSessao,
			dadosUsuario: state => state.dadosUsuario,
			servidores: state => state.servidores,
			serverS: state => state.serverS,
			urlRest: state => state.urlRest
		})
	},
	components: {
		server
	},
	methods: {
		searchServers : function() {
			this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'get',
				url: this.urlRest + 'servicos/searchServers',
				params: {
					value: this.pesquisa.valor
				}
			}).then((responde) => {
				this.pesquisa.resultado = responde.data

			}).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t("serverSettings.swal.expired")
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Error: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		selectServer : function (server) {
			this.server = JSON.parse(JSON.stringify(server))
			$('#modalServer').modal('show')
		},
		savePremium : function () {
         this.$store.dispatch('alternarTelaCarregamento', true)

			if (this.server.premium == true && isNaN(parseInt(this.server.premiumDays))) {
				this.server.premiumDays = 1
			}

         this.$axios({
            method: 'post',
            url: this.urlRest +'servicos/saveServerPremium',
				params: {
					idServer: this.server.id_s,
					premium: this.server.premium,
					premiumPack: this.server.premiumPack,
					days: this.server.premiumDays
				}
         }).then(() => {
				this.searchServers()
            $('#modalServer').modal('hide')

            this.$toast.fire({
               icon: 'success',
               title: 'Saved server!'
            })
         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t("serverSettings.swal.expired")
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Error: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
		}
	}
}

</script>